

















































import { ref, defineComponent } from "@vue/composition-api";

import { AccountsClient, LoginModel } from "@/api/OtiumAppApi";
import { useUserStore, LoginStatus } from "@/stores/userStore";
import { HelpSection } from "@/constants/helpSections";
import { useHelpDialogStore } from "@/stores/helpDialogStore";

export default defineComponent({
  // The warning because root is unavailable in Vue 3 however we are using Vue 2.
  // Cannot use use-router from vue-router as this specific to Vue 3.
  setup(props, { root }) {
    const isFormValid = ref(false);
    const isLoggingIn = ref(false);
    const username = ref("");
    const password = ref("");
    const showPassword = ref(false);
    const usernameRules = ref([(v: string) => !!v || "Username is required"]);
    const passwordRules = ref([(v: string) => !!v || "Password is required"]);
    const errorMessage = ref(undefined as string | undefined);
    const unconfirmedEmail = ref("");
    const resentEmailConfirmation = ref(false);

    const userStore = useUserStore();

    async function loginClicked() {
      isLoggingIn.value = true;
      errorMessage.value = "";
      unconfirmedEmail.value = "";

      let model = {
        email: username.value,
        password: password.value
      } as LoginModel;

      const result = await userStore.login(model);

      isLoggingIn.value = false;
      switch (result) {
        case LoginStatus.Success:
          if (!userStore.userProfile?.hasViewedInitialHelper) {
            const helpDialogStore = useHelpDialogStore();
            helpDialogStore.openDialog(HelpSection.Menu);

            const client = new AccountsClient();
            client.viewHelper();
          }

          if (!userStore.hasOrganization) {
            root.$router.push({ name: "NoOrganizations" });
            break;
          }
          // Redirect to dashboard
          root.$router.push("/dashboard");
          break;

        case LoginStatus.IncorrectUsernameOrPassword:
          errorMessage.value = "Wrong Email or Password.";
          break;

        case LoginStatus.UnverifiedEmail:
          resentEmailConfirmation.value = false;
          unconfirmedEmail.value = model.email;
          break;

        default:
          errorMessage.value = "An unexpected error occurred.";
          break;
      }
    }

    const resendConfirmationEmail = () => {
      resentEmailConfirmation.value = true;
      const client = new AccountsClient();
      client.resendConfirmationEmail(unconfirmedEmail.value);
    };

    return {
      isFormValid,
      isLoggingIn,
      username,
      password,
      showPassword,
      usernameRules,
      passwordRules,
      errorMessage,
      unconfirmedEmail,
      resentEmailConfirmation,
      loginClicked,
      resendConfirmationEmail
    };
  }
});
