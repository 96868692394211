






























import { defineComponent } from "@vue/composition-api";
import AuthenticationBasePage from "@/components/AuthenticationBasePage.vue";
import LoginForm from "@/components/authentication/LoginForm.vue";
import ForgotPasswordDialog from "@/components/authentication/ForgotPasswordDialog.vue";

export default defineComponent({
  name: "Login",
  components: { AuthenticationBasePage, LoginForm, ForgotPasswordDialog },
  setup() {
    const marketingSignUpUrl = process.env.VUE_APP_MARKETING_REGISTER;

    return { marketingSignUpUrl };
  }
});
