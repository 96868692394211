



















































import { AccountsClient, IForgotPasswordModel, ForgotPasswordModel } from "@/api/OtiumAppApi";
import { ref, defineComponent } from "@vue/composition-api";

export default defineComponent({
  setup() {
    const showForgotPasswordDialog = ref(false);
    const email = ref("");
    const isFormValid = ref(false);
    const errorMessage = ref("");
    const resettingPassword = ref(false);
    const usernameRules = ref([(v: string) => !!v || "Email is required"]);

    async function forgotPasswordSubmitted() {
      resettingPassword.value = true;

      const accountsClient = new AccountsClient();

      let model: IForgotPasswordModel = {
        email: email.value,
      };

      await accountsClient.forgotPassword(new ForgotPasswordModel(model));

      resettingPassword.value = false;
      showForgotPasswordDialog.value = false;
    };

    return {
      showForgotPasswordDialog,
      email,
      isFormValid,
      errorMessage,
      usernameRules,
      resettingPassword,
      forgotPasswordSubmitted
    };
  }
});
